import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/AgregarUsuario.css';
import Api from '../api';


const AgregarUsuario = () => {
    const [roles, setRoles] = useState([]);
    const [usuario, setUsuario] = useState(''); // Estado para el nombre de usuario
    const [contrasena, setContrasena] = useState(''); // Estado para la contraseña
    const [rolId, setRolId] = useState(''); // Estado para el rol seleccionado

    useEffect(() => {
        const cargarRoles = async () => {
            try {
                const response = await Api.get('/generales/roles');
                setRoles(response.data);
            } catch (error) {
                console.error('Error cargando los roles:', error);
            }
        };

        cargarRoles();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await Api.post('/usuarios/registrar', { usuario, contrasena, rol_id: rolId });
            alert(response.data.message); // Muestra un mensaje de éxito
            // Restablece los estados a sus valores por defecto
            setUsuario('');
            setContrasena('');
            setRolId('');
        } catch (error) {
            console.error('Error al registrar el usuario:', error);
            alert('Error al registrar el usuario');
        }
    };

    return (
        <div className="pagina-container">
            <div className="registro-usuario-container">
                <h2>Formulario de Registro de Usuario</h2>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="username">Nombre de Usuario:</label><br />
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={usuario}
                        onChange={(e) => setUsuario(e.target.value)}
                        required
                    /><br />
                    <label htmlFor="password">Contraseña:</label><br />
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={contrasena}
                        onChange={(e) => setContrasena(e.target.value)}
                        required
                    /><br />
                    <label htmlFor="permission">Permiso:</label><br />
                    <select
                        id="permission"
                        name="permission"
                        value={rolId}
                        onChange={(e) => setRolId(e.target.value)}
                        required
                    >
                        <option value="">Seleccione un permiso</option>
                        {roles.map((rol) => (
                            <option key={rol.id} value={rol.id}>{rol.nombre}</option>
                        ))}
                    </select><br /><br />
                    <input type="submit" value="Registrar" />
                </form>
            </div>
        </div>
    );
}

export default AgregarUsuario;
