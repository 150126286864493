import React from 'react';

const FirmaPermisionario = () => {
  return (
    <div>
      <h2>¡Bienvenido a la sección de Firma Permisionario!</h2>
      <p>Este es un lugar para contenido principal.</p>
    </div>
  );
}

export default FirmaPermisionario;
