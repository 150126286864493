import React, { useEffect, useState, useMemo } from 'react';
import { useTable, useFilters } from 'react-table';
import Api from '../api'; // Asegúrate de ajustar la ruta según sea necesario.
import '../css/Tareas.css';
import moment from 'moment-timezone';

const Tareas = () => {
  const [tareas, setTareas] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [prioridades, setPrioridades] = useState([]);
  const [estados, setEstados] = useState([]);
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');

  const cargarDatos = async () => {
    // const usuarioId = localStorage.getItem('usuario_id');
    try {
      // Carga simultánea de tareas, proyectos, prioridades y estados
      const [resTareas, resProyectos, resPrioridades, resEstados] = await Promise.all([
        // Api.get(`/tareas/tareasPorUsuario/${usuarioId}`),
        Api.get('/tareas/capturarTareas'),
        Api.get('/generales/proyectos'),
        Api.get('/generales/prioridades'),
        Api.get('/generales/estados'),
      ]);
      setTareas(resTareas.data);
      setProyectos(resProyectos.data);
      setPrioridades(resPrioridades.data);
      setEstados(resEstados.data);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  useEffect(() => {
    cargarDatos();
  }, [fechaInicio, fechaFin]);

  const onSave = async (tareaId, nuevoEstadoId, verificadoSeleccionado) => {
    // Asegurarse de que solo se actualiza el estado si se ha proporcionado un nuevoEstadoId válido
    if (nuevoEstadoId !== undefined && nuevoEstadoId !== '') {
      try {
        await Api.put(`/tareas/actualizarEstado/${tareaId}`, { estado: nuevoEstadoId });
        console.log('Estado actualizado con éxito.');
      } catch (error) {
        console.error('Error al actualizar el estado:', error);
      }
    }
    
    // Asegurarse de que solo se actualiza la verificación si se ha proporcionado un verificadoSeleccionado válido
    if (verificadoSeleccionado !== undefined) {
      const payload = {
        verificado: verificadoSeleccionado,
        fecha_verificado: verificadoSeleccionado === '1' ? moment().tz("America/Mexico_City").format('YYYY-MM-DD HH:mm:ss') : null,
      };
    
      try {
        await Api.put(`/tareas/actualizarVerificacion/${tareaId}`, payload);
        console.log('Verificación actualizada con éxito.');
      } catch (error) {
        console.error('Error al actualizar la verificación:', error);
      }
    }
    
    setIsModalOpen(false);
    cargarDatos();
  };
  
  const tareasFiltradasPorFecha = useMemo(() => {
    if (!fechaInicio && !fechaFin) return tareas;
    return tareas.filter(tarea => {
      const fechaTarea = new Date(tarea.fecha_seteado.split('T')[0]);
      const inicio = fechaInicio ? new Date(fechaInicio) : new Date('1970-01-01');
      const fin = fechaFin ? new Date(fechaFin) : new Date('2999-12-31');
      return fechaTarea >= inicio && fechaTarea <= fin;
    });
  }, [tareas, fechaInicio, fechaFin]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const handleRowClick = (row) => {
    setSelectedRowData(row.original);
    setIsModalOpen(true);
  };

  const data = useMemo(() => tareasFiltradasPorFecha, [tareasFiltradasPorFecha]);

  const FiltroCombobox = ({ column: { setFilter }, opciones }) => (
    <select
      onChange={e => setFilter(e.target.value || undefined)}
    >
      <option value="">Todos</option>
      {opciones.map((opcion, i) => (
        <option key={i} value={opcion}>{opcion}</option>
      ))}
    </select>
  );

  const columns = useMemo(() => [
    {
        Header: 'Personal',
        accessor: 'nombrePersonal',
        disableFilters: true,
    },
    {
      Header: 'Proyecto',
      accessor: 'proyecto',
      Filter: ({ column }) => <FiltroCombobox column={column} opciones={proyectos.map(p => p.nombre)} />,
    },
    {
        Header: 'Actividad',
        accessor: 'actividad',
        disableFilters: true,
      },
    {
      Header: 'Prioridad',
      accessor: 'prioridad',
      Filter: ({ column }) => <FiltroCombobox column={column} opciones={prioridades.map(p => p.nombre)} />,
    },
    {
      Header: 'Estado',
      accessor: 'estado',
      Filter: ({ column }) => <FiltroCombobox column={column} opciones={estados.map(e => e.nombre)} />,
    },
    {
        Header: 'Verificado',
        accessor: 'verificado',
        disableFilters: true,
        Cell: ({ value }) => value === 0 ? 'No' : 'Sí',
      },

  ], [proyectos, prioridades, estados]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters
  );

  const Modal = ({ isOpen, onClose, data }) => {
    const [selectedEstadoId, setSelectedEstadoId] = useState('');
    const [verificadoSeleccionado, setVerificadoSeleccionado] = useState(data?.verificado.toString());
    

    useEffect(() => {
      if (isOpen && data) {
        setSelectedEstadoId(data.estadoId || '');
        setVerificadoSeleccionado(data?.verificado.toString()); // Asegúrate de convertirlo a cadena para que coincida con los valores del combobox
      }
    }, [isOpen, data]);
  
    const handleSaveClick = () => {
      onSave(data.id, selectedEstadoId, verificadoSeleccionado);
    };

    if (!isOpen) return null;

    return (
      <div className="modal-backdrop" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <h2>Detalles de la Tarea</h2>
          <p>Encargado: {data?.nombreEncargado}</p>
          <p>Personal: {data?.nombrePersonal}</p>
          <p>Fecha Seteado: {moment(data?.fecha_seteado).tz("America/Mexico_City").format('YYYY-MM-DD HH:mm:ss')}</p>
          <p>Fecha Finalizado: {moment(data?.fecha_realizado).tz("America/Mexico_City").format('YYYY-MM-DD HH:mm:ss')}</p>
          <p>Verificado:
            <select value={verificadoSeleccionado} onChange={e => setVerificadoSeleccionado(e.target.value)}>
              <option value="0">No</option>
              <option value="1">Sí</option>
            </select>
          </p>
          <p>Fecha Verificado: {moment(data?.fecha_verificado).tz("America/Mexico_City").format('YYYY-MM-DD HH:mm:ss')}</p>
          {/* <p>Proyecto: {data?.proyecto}</p> */}
          {/* <p>Prioridad: {data?.prioridad}</p> */}
          <p>Estado:
            <select value={selectedEstadoId} onChange={(e) => setSelectedEstadoId(e.target.value)}>
              {estados.map(estado => (
                <option key={estado.id} value={estado.id}>{estado.nombre}</option>
              ))}
            </select>
          </p>
          {/* <p>Actividad: {data?.actividad}</p> */}
          <button onClick={handleSaveClick}>Guardar</button>
          <button onClick={onClose}>Cerrar</button>
        </div>
      </div>
    );
  };


  // Dentro de tu componente Tareas, al final antes del return

  return (
    <div className="tareas-listado-container">
      <h2 id="encabezado-tareas-asignadas">Tareas Asignadas</h2>
      <div className="fechas-container">
        <input type="date" className="fecha-input" value={fechaInicio} onChange={e => setFechaInicio(e.target.value)} />
        <input type="date" className="fecha-input" value={fechaFin} onChange={e => setFechaFin(e.target.value)} />
      </div>

      <div className="tareas-listado-container">
        <table {...getTableProps()} className="tareas-tabla">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                    {/* Renderiza el filtro si la columna puede ser filtrada */}
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps({ onClick: () => handleRowClick(row) })}>
                  {row.cells.map(cell => {
                    // Aplica la clase 'actividad' condicionalmente si el accessor de la celda es 'actividad'
                    const cellClass = cell.column.id === 'actividad' ? 'actividad' : '';
                    return (
                      <td {...cell.getCellProps()} className={cellClass}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} data={selectedRowData} estados={estados} />
    </div>
  );
};

export default Tareas;
