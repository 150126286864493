import React from 'react';

const MirarGaleria = () => {
  return (
    <div>
      <h2>¡Bienvenido a la sección de Mirar Galeria!</h2>
      <p>Este es un lugar para contenido principal.</p>
    </div>
  );
}

export default MirarGaleria;
