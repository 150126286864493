// icons.js
import { faGauge, faUserPlus, faClipboard, faThumbTack, faListCheck, faBook, faTarp, faScroll, faMagnifyingGlass, faMagnifyingGlassChart, faCircleXmark, faNewspaper, faUserPen, faIdBadge, faCity, faUserShield, faImage, faCamera, faDollarSign } from '@fortawesome/free-solid-svg-icons';

export const icons = {
  1: { icon: faGauge, color: '#FF6347', name: 'Dashboard' },
  2: { icon: faUserPlus, color: '#FFA07A', name: 'Agregar Usuario' },
  3: { icon: faClipboard, color: '#20B2AA', name: 'Autotarea' },
  4: { icon: faThumbTack, color: '#FFD700', name: 'Asignar Tarea' },
  5: { icon: faListCheck, color: '#00FFFF', name: 'Tareas' },
  6: { icon: faBook, color: '#00FA9A', name: 'Bitacora Tareas' },
  7: { icon: faTarp, color: '#7FFFD4', name: 'Proyectos' },
  8: { icon: faScroll, color: '#B0C4DE', name: 'Roles' },
  9: { icon: faMagnifyingGlass, color: '#DC143C', name: 'Observaciones' },
  10: { icon: faMagnifyingGlassChart, color: '#FF1493', name: 'Bitacora Observaciones' },
  11: { icon: faNewspaper, color: '#8A2BE2', name: 'Reportes' }, // Cambiado a azul violeta
  12: { icon: faIdBadge, color: '#A52A2A', name: 'Bitacora Permisionario' }, // Cambiado a marrón
  13: { icon: faUserPen, color: '#DEB887', name: 'Firma Permisionario' }, // Cambiado a burlywood
  14: { icon: faCity, color: '#5F9EA0', name: 'Informacion Empresas' }, // Cambiado a cadetblue
  15: { icon: faUserShield, color: '#D2691E', name: 'Informacion Fundador' }, // Cambiado a chocolate
  16: { icon: faImage, color: '#6495ED', name: 'Mirar Galeria' }, // Cambiado a cornflowerblue
  17: { icon: faCamera, color: '#DCDCDC', name: 'Subir Foto' }, // Cambiado a gainsboro
  18: { icon: faDollarSign, color: '#FF69B4', name: 'Tarifa' }, // Cambiado a hotpink

  50: { icon: faCircleXmark, color: 'red', name: 'Proximamente' } // Iconos especiales con ID 50
};
