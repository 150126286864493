// Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from './Icons'; // Asegúrate de tener este objeto de iconos correctamente definido
import SmartToolsIcon from '../img/IconoSmartTools.png';

const Navbar = ({ onLogout }) => {
  const [collapsed, setCollapsed] = React.useState(false);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  // Función para verificar si el usuario tiene el permiso
  const checkPermissionById = (permissionId) => {
    const permisos = JSON.parse(localStorage.getItem('permisos') || '[]');
    return permisos.includes(permissionId);
  };

  const menuItems = [
    { id: 1, to: "/", text: "Dashboard", iconId: 1 },
    { id: 2, to: "/dashboard/agregarusuario", text: "Agregar Usuario", iconId: 2 },
    { id: 3, to: "/dashboard/autotareas", text: "Autotareas", iconId: 3 },
    { id: 4, to: "/dashboard/asignartarea", text: "Asignar Tareas", iconId: 4 },
    { id: 5, to: "/dashboard/tareas", text: "Tareas", iconId: 5 },
    { id: 6, to: "/dashboard/bitacoratareas", text: "Bitacora Tareas", iconId: 6 },
    { id: 7, to: "/dashboard/proyectos", text: "Proyecto", iconId: 7 },
    { id: 8, to: "/dashboard/roles", text: "Roles", iconId: 8 },
    { id: 9, to: "/dashboard/observaciones", text: "Observaciones", iconId: 9 },
    { id: 10, to: "/dashboard/bitacoraobservaciones", text: "Bitacora Observaciones", iconId: 10 },
    { id: 11, to: "/dashboard/reportes", text: "Reportes", iconId: 11 },
    { id: 12, to: "/dashboard/bitacorapermisionario", text: "Bitacora Permisionarios", iconId: 12 },
    { id: 13, to: "/dashboard/firmapermisionario", text: "Firma Permisionario", iconId: 13 },
    { id: 14, to: "/dashboard/infoempresas", text: "Información Empresas", iconId: 14 },
    { id: 15, to: "/dashboard/infofundador", text: "Información Fundador", iconId: 15 },
    { id: 16, to: "/dashboard/mirargaleria", text: "Mirar Galeria", iconId: 16 },
    { id: 17, to: "/dashboard/subirfoto", text: "Subir Foto", iconId: 17 },
    { id: 18, to: "/dashboard/tarifa", text: "Tarifas", iconId: 18 },
  ];

  return (
    <div className={`navbar ${collapsed ? 'collapsed' : ''}`}>
      <div className="navbar-header">
        <button className="toggle-button" onClick={toggleNavbar}>
          {collapsed ? <img src={SmartToolsIcon} alt="Hamburger Icon" /> : 'Smart Tools'}
        </button>
      </div>
      <div className="navbar-content">
        <ul>
          {menuItems.map((item) =>
            checkPermissionById(item.id) && (
              <li key={item.id}>
                <Link to={item.to}>
                  <FontAwesomeIcon
                    icon={icons[item.iconId].icon}
                    color={icons[item.iconId].color}
                    className={collapsed ? 'icon-centered' : ''}
                  />
                  <span className={`button-text ${collapsed ? 'hidden' : ''}`}>{item.text}</span>
                </Link>
              </li>
            )
          )}
        </ul>
        <div className="logout-section">
          <button onClick={onLogout} className="logout-button">Logout</button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
