import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/AsignarTarea.css'; // Asegúrate de tener este archivo CSS
import Api from '../api';

const AsignarTarea = () => {
    const [usuarios, setUsuarios] = useState([]);
    const [proyectos, setProyectos] = useState([]);
    const [prioridades, setPrioridades] = useState([]);
    const [estados, setEstados] = useState([]);
    const [tarea, setTarea] = useState({
        personal: '',
        proyecto: '',
        actividad: '',
        prioridad: '',
        estado: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resUsuarios = await Api.get('/generales/usuarios');
                setUsuarios(resUsuarios.data);
                const resProyectos = await Api.get('/generales/proyectos');
                setProyectos(resProyectos.data);
                const resPrioridades = await Api.get('/generales/prioridades');
                setPrioridades(resPrioridades.data);
                const resEstados = await Api.get('/generales/estados');
                setEstados(resEstados.data);
            } catch (error) {
                console.error('Error cargando los datos:', error);
            }
        };
        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTarea(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const encargado = parseInt(localStorage.getItem('usuario_id'), 10);

        const tareaConEncargado = { ...tarea, encargado };
        try {
            // console.log('ID del encargado antes de enviar:', tareaConEncargado.encargado);
            await Api.post('/tareas/registrar', tareaConEncargado);
            alert('Tarea asignada con éxito');
            // Limpia el formulario
            setTarea({
                personal: '',
                proyecto: '',
                actividad: '',
                prioridad: '',
                estado: ''
            });
        } catch (error) {
            console.error('Error al asignar la tarea:', error);
            alert('Error al asignar la tarea');
        }
    };

    return (
        <div className="asignar-tarea-container">
            <div className="asignar-tarea-form-container">
                <h2>Asignar Tarea</h2>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="personal">Personal:</label>
                    <select id="personal" name="personal" value={tarea.personal} onChange={handleChange} required>
                        <option value="">Seleccione un usuario</option>
                        {usuarios.map(usuario => (
                            <option key={usuario.id} value={usuario.id}>{usuario.nombre}</option>
                        ))}
                    </select>

                    <label htmlFor="proyecto">Proyecto:</label>
                    <select id="proyecto" name="proyecto" value={tarea.proyecto} onChange={handleChange} required>
                        <option value="">Seleccione un proyecto</option>
                        {proyectos.map(proyecto => (
                            <option key={proyecto.id} value={proyecto.id}>{proyecto.nombre}</option>
                        ))}
                    </select>

                    <label htmlFor="actividad">Actividad:</label>
                    <input type="text" id="actividad" name="actividad" value={tarea.actividad} onChange={handleChange} required />

                    <label htmlFor="prioridad">Prioridad:</label>
                    <select id="prioridad" name="prioridad" value={tarea.prioridad} onChange={handleChange} required>
                        <option value="">Seleccione una prioridad</option>
                        {prioridades.map(prioridad => (
                            <option key={prioridad.id} value={prioridad.id}>{prioridad.nombre}</option>
                        ))}
                    </select>

                    <label htmlFor="estado">Estado:</label>
                    <select id="estado" name="estado" value={tarea.estado} onChange={handleChange} required>
                        <option value="">Seleccione un estado</option>
                        {estados.map(estado => (
                            <option key={estado.id} value={estado.id}>{estado.nombre}</option>
                        ))}
                    </select>

                    <input type="submit" value="Asignar" />
                </form>
            </div>
        </div>
    );
}

export default AsignarTarea;
