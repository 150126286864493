import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import AgregarUsuario from './pages/AgregarUsuario';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// Importa más componentes según tus necesidades

function Dashboard({}) {

      let navigate = useNavigate();

      const handleLogout = () => {
          localStorage.removeItem('isAuthenticated'); // Limpia el estado de autenticación
          // setIsAuthenticated(false)
          // navigate('/login');
          window.location.href = '/login';
      };
    return (
      <div>
        <Navbar onLogout={handleLogout}/>
        <div className="dashboard-content">
          <Outlet />
        </div>
      </div>
    );
  }

export default Dashboard;
