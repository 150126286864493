import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import AgregarUsuario from './pages/AgregarUsuario';
import Autotareas from './pages/Autotareas';
import AsignarTarea from './pages/AsignarTareas';
import Tareas from './pages/Tareas';
import BitacoraTareas from './pages/BitacoraTareas';
import Proyectos from './pages/Proyectos';
import Roles from './pages/Roles';
import Observaciones from './pages/Observaciones';
import BitacoraObservaciones from './pages/BitacoraObservaciones';
import BitacoraPermisionario from './pages/BitacoraPermisionario';
import FirmaPermisionario from './pages/FirmaPermisionario';
import InformacionEmpresas from './pages/InformacionEmpresas';
import InformacionFundador from './pages/InformacionFundador';
import MirarGaleria from './pages/MirarGaleria';
import SubirFoto from './pages/SubirFoto';
import Tarifa from './pages/Tarifa';
import Reportes from './pages/Reportes';



function App() {
  const usuario = localStorage.getItem('usuario');
  const permiso = localStorage.getItem('nombre_rol');
  const permisos = localStorage.getItem('permisos');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Comprobar el estado de autenticación al cargar la app
    const authStatus = localStorage.getItem('isAuthenticated') === 'true';
    setIsAuthenticated(authStatus);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated'); // Limpia el estado de autenticación en localStorage
    setIsAuthenticated(false); // Actualiza el estado de autenticación en la app
    // No puedes usar navigate aquí directamente porque useNavigate debe usarse dentro de un componente funcional
    // Considera redirigir usando un enfoque diferente o asegurarte de que la app reaccione adecuadamente al cambio de estado
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={!isAuthenticated ? <Login setIsAuthenticated={setIsAuthenticated} /> : <Navigate replace to="/dashboard" />} />
        <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate replace to="/" />}>
          <Route index element={<div>Inicio Dashboard
            <h2>xd</h2>
            <p>Bienvenid@</p>
            {/* <br></br> */}
            <p>Usuario: {usuario}</p>
            <p>Rol: {permiso}</p>
            <p>{permisos}</p>

          </div>} />
          <Route path="agregarusuario" element={<AgregarUsuario />} />
          <Route path="autotareas" element={<Autotareas />} />
          <Route path="asignartarea" element={<AsignarTarea />} />
          <Route path="tareas" element={<Tareas />} />
          <Route path="bitacoratareas" element={<BitacoraTareas />} />
          <Route path="proyectos" element={<Proyectos />} />
          <Route path="roles" element={<Roles />} />
          <Route path="observaciones" element={<Observaciones />} />
          <Route path="bitacoraobservaciones" element={<BitacoraObservaciones />} />
          <Route path="reportes" element={<Reportes />} />
          <Route path="bitacorapermisionario" element={<BitacoraPermisionario />} />
          <Route path="firmapermisionario" element={<FirmaPermisionario />} />
          <Route path="infoempresas" element={<InformacionEmpresas />} />
          <Route path="infofundador" element={<InformacionFundador />} />
          <Route path="mirargaleria" element={<MirarGaleria />} />
          <Route path="subirfoto" element={<SubirFoto />} />
          <Route path="tarifa" element={<Tarifa />} />
        </Route>
        <Route path="*" element={<Navigate replace to={isAuthenticated ? "/dashboard" : "/"} />} />
      </Routes>
    </Router>
  );
}

export default App;
