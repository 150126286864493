import React, { useEffect, useState } from 'react';
import Api from '../api'; // Ajusta la ruta según sea necesario.
import '../css/Autotareas.css';

const Autotareas = () => {
  const [proyectos, setProyectos] = useState([]);
  const [proyectoSeleccionado, setProyectoSeleccionado] = useState('');
  const [actividad, setActividad] = useState('');

  useEffect(() => {
    const cargarProyectos = async () => {
      try {
        const res = await Api.get('/generales/proyectos');
        setProyectos(res.data);
      } catch (error) {
        console.error('Error al obtener los proyectos:', error);
      }
    };

    cargarProyectos();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const usuarioId = localStorage.getItem('usuario_id');
    const data = {
      personal: usuarioId,
      proyecto: proyectoSeleccionado,
      actividad: actividad,
      fecha_registro: new Date().toISOString(), // Esto establecerá la fecha y hora actual
    };

    try {
      await Api.post('/tareas/registrarAutotarea', data);
      alert('Tarea creada con éxito');
    } catch (error) {
      console.error('Error al crear la tarea:', error);
      alert('Error al crear la tarea');
    }
  };

  return (
      <div class="autotareas-container">
        <div class="autotareas-form-container">
          <form onSubmit={handleSubmit}>
            <div class="form-group">
              <label for="proyecto">Proyecto:</label>
              <select id="proyecto" value={proyectoSeleccionado} onChange={(e) => setProyectoSeleccionado(e.target.value)} required>
                <option value="">Seleccione un proyecto</option>
                {proyectos.map((proyecto) => (
                  <option key={proyecto.id} value={proyecto.id}>{proyecto.nombre}</option>
                ))}
              </select>
            </div>
            <div class="form-group">
              <label for="actividad">Actividad:</label>
              <input type="text" id="actividad" value={actividad} onChange={(e) => setActividad(e.target.value)} required />
            </div>
            <div class="form-group">
              <button type="submit" class="btn-submit">Guardar</button>
            </div>
          </form>
        </div>
      </div>

  );
};

export default Autotareas;
