import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate en lugar de useHistory
import './css/Login.css';
import IconoST from './img/IconoSmartTools.png';
// import IconoST from './img/inspirasao.png';
// import IconoST from './img/xd.jpeg';
import Api from './api';

function Login({ setIsAuthenticated }) {
    const [usuario, setUsuario] = useState('');
    const [contrasena, setContrasena] = useState('');
    const navigate = useNavigate(); // Utiliza useNavigate aquí

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await Api.post('/usuarios/login', { usuario, contrasena });

            // La respuesta directamente tiene la data en caso de éxito con Axios
            console.log(response.data);
    
            // Guardando la información del usuario y sus permisos en localStorage
            localStorage.setItem('usuario_id', response.data.id); // Guarda el ID del usuario en localStorage
            localStorage.setItem('usuario', response.data.usuario);
            localStorage.setItem('rol_id', response.data.rol_id);
            localStorage.setItem('nombre_rol', response.data.nombre_rol);
            // Guardando los permisos como un string JSON ya que localStorage solo soporta strings
            localStorage.setItem('permisos', JSON.stringify(response.data.permisos));
            setIsAuthenticated(true);
            localStorage.setItem('isAuthenticated', 'true');
            navigate('/dashboard');
        } catch (error) {
            console.error('Inicio de sesión fallido:', error);
            alert('Inicio de sesión fallido');
        }
    };
    

    return (
        <div className="login-wrapper">
            <div className="login-container">
                <img src={IconoST} alt="Logo" className="login-logo" />
                <h2>Login</h2>
                <form onSubmit={handleSubmit} className="login-form">
                    <input
                        type="text"
                        value={usuario}
                        name="usuario"
                        onChange={e => setUsuario(e.target.value)}
                        placeholder="Usuario"
                        required
                    />
                    <input
                        type="password"
                        value={contrasena}
                        name="contrasena"
                        onChange={e => setContrasena(e.target.value)}
                        placeholder="Contraseña"
                        required
                    />
                    <button type="submit">Iniciar Sesión</button>
                </form>
            </div>
        </div>
    );
}

export default Login;
