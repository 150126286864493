import React from 'react';

const Tarifa = () => {
  return (
    <div>
      <h2>¡Bienvenido a la sección de Tarifas!</h2>
      <p>Este es un lugar para contenido principal.</p>
    </div>
  );
}

export default Tarifa;
