import React from 'react';

const Roles = () => {
  return (
    <div>
      <h2>¡Bienvenido a la sección de Roles!</h2>
      <p>Este es un lugar para contenido principal.</p>
    </div>
  );
}

export default Roles;
